<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="break-word"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="break-word"
      >
        <v-chip
          v-for="(subsis, index) in formattedItem.arraySubsistemas"
          :key="index"
          label
          :color="formattedItem.colorSubsistema"
          dark
          small
          class="ml-1 mt-1"
        >
          {{ subsis }}
        </v-chip>
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.caducada"
      >
        <v-chip
          label
          color="error"
          dark
          small
          class="ml-1"
        >
          Caducada
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'
import { nonEmpty } from '@/utils/templates'
import { currentDate } from '@/utils/date'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.tactuacion.descripcion} ${item.periodo_revision.descripcion}`)
      const fultRevision = this.$options.filters.shortDate(item.sistema_mant.fultrevision)
      const fproxRevision = this.$options.filters.shortDate(item.sistema_mant.fproxrevision)
      const fplanificacion = this.$options.filters.shortDate(item.sistema_mant.fplanificacion)
      item.subtitle = nonEmpty`
        Última ${fultRevision} - Próxima ${fproxRevision} - Planificada ${fplanificacion}
      `
      item.avatar = this.$options.filters.initials(item.periodo_revision.descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.periodo_revision.descripcion))
      item.caducada = item.sistema_mant.fproxrevision < currentDate()
      if (item.sistema_mant.subsistemas) {
        item.arraySubsistemas = item.sistema_mant.subsistemas.split(', ')
        item.colorSubsistema = "info"
      } else {
        item.arraySubsistemas = ['Sin subsistema']
        item.colorSubsistema = "warning"
      }
      return item
    }
  },
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
